import React, { Component } from 'react'
import Header from '@components/header'
import Sidebar from '@components/sidebar'
import MyTabs from './myTabs'
import '@styles/home.less'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import api from '@api/index'
import { Message, MessageBox } from 'element-react'
import apiV2 from '../api/api-v2'
import { ACCOUNT_MODE, APP_NAME } from '../utils/app'
import { ThemeConfig } from '../utils/format'

class Home extends Component {
	static propTypes = {
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		children: PropTypes.node.isRequired
	}

	static SysUserInfoEmpty = {
		sumMoney: 0,
		money: 0
	}

	static EnableOldStatic = false

	constructor(props) {
		super(props)
		this.state = {
			recharge: 0, // 充值总金额
			consume: 0, // 已消费
			consumeLowerLimit: 0, // 系统费用最低=提醒
			residue: 0, // 剩余 = 充值总金额 - 消耗费用
			sysUserInfo: Home.SysUserInfoEmpty
		}
	}
	componentWillMount() {
		console.log('home')
		console.log(this.props)
		this.getUserInfo()
	}
	componentDidMount() {
		this.selectGlobalValue()
	}

	async getUserInfo() {
		const body = await apiV2.getUserInfo()
		if (body) this.setState({ sysUserInfo: body })
	}

	selectGlobalValue = async () => {
		if (Home.EnableOldStatic) {
			const res = await api.selectGlobalValueApi()
			if (res.success) {
				if (parseInt(res.data.balance) <= res.data.consumeLowerLimit) {
					MessageBox.confirm('余额即将不足', '提示', {
						type: 'warning'
					}).then(() => {

					}).catch(() => {

					})
				}
				this.setState({
					recharge: res.data.recharge,
					consume: res.data.consume,
					consumeLowerLimit: res.data.consumeLowerLimit,
					residue: res.data.balance
				})
			} else {
				Message.error(res.msg)
			}
		}

		const { body, success, message, status } = await apiV2.getPlatformMoney({})
		if (success) {
			const { balance, consumerMoney, sumMoney } = body
			this.setState({
				recharge: sumMoney,
				consume: consumerMoney,
				consumeLowerLimit: balance
			})
		} else if (status !== 404) {
			Message.error(message)
		}
	}

	get totalRecharge() {
		switch (ACCOUNT_MODE) {
			case 'recharge': {
				return this.state.sysUserInfo.sumMoney
			}
			default: {
				return this.state.recharge
			}
		}
	}

	get totalConsume() {
		switch (ACCOUNT_MODE) {
			case 'recharge': {
				return this.state.sysUserInfo.money
			}
			default: {
				return this.state.consume
			}
		}
	}

	get totalResidue() {
		switch (ACCOUNT_MODE) {
			case 'recharge': {
				return this.state.sysUserInfo.sumMoney - this.state.sysUserInfo.money
			}
			default: {
				return this.state.residue
			}
		}
	}

	render() {
		const state = this.props.location.state
		let go = null
		if (state) {
			go = state.go
		}

		const time = new Date()
		const { children } = this.props

		const menuStyle = {}
		if (ThemeConfig.primaryColorLight) {
			menuStyle.backgroundColor = ThemeConfig.primaryColorLight
		}

		const topStyle = {}
		if (ThemeConfig.primaryColor) {
			topStyle.backgroundColor = ThemeConfig.primaryColor
		}

		return (
			<div className="flex flex-direction_column">
				<div className="header" style={topStyle}>
					<Header history={this.props.history} />
				</div>
				<ul className="flex flex-diredtion_row container">
					<li className="sidebar" style={menuStyle}>
						<Sidebar history={this.props.history} goPage={go} />
					</li>
					<li className="main">
						<MyTabs history={this.props.history} />
						<div className="content" key={time}>
							{children}
						</div>
					</li>
				</ul>
				<ul className="footer flex flex-direction_row">
					<li>{'©2024'}</li>

					<li>{`系统名称 ${APP_NAME}后台管理系统`}</li>
					<li>{'消费环境开启状态'}</li>
					<li>{'总充值'}{this.totalRecharge}{'¥'}</li>
					<li>{'已消费'}{this.totalConsume}{'¥'}</li>
					<li>{'剩余'}{this.totalResidue}{'¥'}</li>
				</ul>
			</div>
		)
	}
}
export default withRouter(Home)
